import 'react-circular-progressbar/dist/styles.css';
import './App.css';
import React, { useState, useEffect } from "react";
import { DayContainer } from './Components/DayContainer';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';


function App() {
  const [iftarProgress,setIftarProgress] = useState(0);
  const [morehProgress,setMorehProgress] = useState(0);
  const [totalProgress,setTotalProgress] = useState(0);
  const [totalDays,setTotalDays] = useState(30);

  const surauData = {
    name: "Musolla Mutiara",
    address: "Residensi Mutiara, Jalan Zamrud Utama, 43000 Kajang, Selangor"
  }
  const result = {
    day1: [true,true],
    day2: [true,false],
    day3: [false,true],
    day4: [false,false],
    day5: [false,false],
    day6: [false,false],
    day7: [false,false],
    day8: [false,false],
    day9: [false,false],
    day10: [false,false],
    day11: [true,true],
    day12: [true,false],
    day13: [false,true],
    day14: [false,false],
    day15: [false,false],
    day16: [false,false],
    day17: [false,false],
    day18: [false,false],
    day19: [false,false],
    day20: [false,false],
    day21: [true,false],
    day22: [true,false],
    day23: [false,true],
    day24: [false,false],
    day25: [false,false],
    day26: [false,false],
    day27: [false,false],
    day28: [false,false],
    day29: [false,false],
    day30: [false,false]
  }

  useEffect(() => {
    let iftarCount = 0;
    let morehCount = 0;
    for (let i = 1; i <= 30; i++) {
      //sum += result[`day${i}`][2];
      if(result[`day${i}`][0] == true){
        iftarCount ++ ;
      }
      if(result[`day${i}`][1] == true){
        morehCount ++ ;
      }
    }
    let iftarCalc = iftarCount/30*100;
    setIftarProgress(iftarCalc);
    let morehCalc = morehCount/30*100;
    setMorehProgress(morehCalc);
    let totalCalc = (iftarCount + morehCount)/60*100;
    setTotalProgress(totalCalc.toFixed(2));
  }, [result]);


  return (
  <div>
    <div className="headerContainer">
      <h1 className='arabFont'>افتر دان موريه</h1>
      <h1>Sumbangan Ramadhan {surauData.name}</h1>
      <h2>{surauData.address}</h2>
    </div>
    <div className='progressBarContainer'>
      <CircularProgressbar
        value={totalProgress}
        text={`${totalProgress}%`}
        background
        backgroundPadding={5}
        styles={buildStyles({
          backgroundColor: totalProgress == "0" ? "#d3d7d8" : totalProgress == "100" ? "#d5ffdd" : "#97d5fc",
          textColor: "#fff",
          pathColor: "#fff",
          trailColor: "transparent",
        })}
      />
      <p>Progres Sumbangan</p>
    </div>
    <div className='calendarContainer'>
      <DayContainer dayData={result.day1} dayNumber={1} />
      <DayContainer dayData={result.day2} dayNumber={2} />
      <DayContainer dayData={result.day3} dayNumber={3} />
      <DayContainer dayData={result.day4} dayNumber={4} />
      <DayContainer dayData={result.day5} dayNumber={5} />
      <DayContainer dayData={result.day6} dayNumber={6} />
      <DayContainer dayData={result.day7} dayNumber={7} />
      <DayContainer dayData={result.day8} dayNumber={8} />
      <DayContainer dayData={result.day9} dayNumber={9} />
      <DayContainer dayData={result.day10} dayNumber={10} />
      <DayContainer dayData={result.day11} dayNumber={11} />
      <DayContainer dayData={result.day12} dayNumber={12} />
      <DayContainer dayData={result.day13} dayNumber={13} />
      <DayContainer dayData={result.day14} dayNumber={14} />
      <DayContainer dayData={result.day15} dayNumber={15} />
      <DayContainer dayData={result.day16} dayNumber={16} />
      <DayContainer dayData={result.day17} dayNumber={17} />
      <DayContainer dayData={result.day18} dayNumber={18} />
      <DayContainer dayData={result.day19} dayNumber={19} />
      <DayContainer dayData={result.day20} dayNumber={20} />
      <DayContainer dayData={result.day21} dayNumber={21} />
      <DayContainer dayData={result.day22} dayNumber={22} />
      <DayContainer dayData={result.day23} dayNumber={23} />
      <DayContainer dayData={result.day24} dayNumber={24} />
      <DayContainer dayData={result.day25} dayNumber={25} />
      <DayContainer dayData={result.day26} dayNumber={26} />
      <DayContainer dayData={result.day27} dayNumber={27} />
      <DayContainer dayData={result.day28} dayNumber={28} />
      <DayContainer dayData={result.day29} dayNumber={29} />
      <DayContainer dayData={result.day30} dayNumber={30} />
    </div>
    <div className="footerContainer">
      <p>Daripada Zaid bin Khalid, bahawasanya Rasulullah SAW bersabda:</p>
      <h1 className='arabFont'>مَن فَطَّرَ صَائِمًا كَانَ لَهُ مِثلُ أَجْرِهِ، غَيْرَ أَنَّهُ لَا يَنْقُصُ مِن أَجْرِ الصَّائِمِ شَيئًا</h1>
      <p>Maksudnya: “Siapa yang memberi makan orang yang berpuasa maka baginya pahala seumpamanya tanpa sedikitpun kurang pahala orang berpuasa.”</p>
      <p>(Riwayat Ahmad, al-Tarmizi dan al-Baihaqi)</p>
      <p style={{fontSize:"0.6vw"}}>Made with ❤️ by <a target="_blank" href='https://fahmijaafar.com'>FJ</a>. © 2024.</p>
    </div>
  </div>
  );
}

export default App;
