export const DayContainer = ({ dayData, dayNumber }) => {
    const isComplete = dayData[0] && dayData[1];  // Check if both iftar and moreh are complete
    const isIncomplete = !isComplete && !dayData[0] && !dayData[1]; // Check if both are incomplete
    const bgClass = isComplete ? "completeBg" : isIncomplete ? "newBg" : "";
    const btnClass = isComplete ? "btn-disabled" : "";
    const btnText = isComplete ? "Terima Kasih" : "Buat Sumbangan";
  
    return (
      <div className="box">
        <div className={`itemContainer ${bgClass}`}>
          <p>Iftar <span className="iconBox">{dayData[0] ? '✔️' : '⭕'}</span></p>
          <p>Moreh <span className="iconBox">{dayData[1] ? '✔️' : '⭕'}</span></p>
          <p className="num">{dayNumber}</p>
          <p><span className="ramTxt">ramadhan</span></p>
          <button className={`button-19 ${btnClass}`}>{btnText}</button>
        </div>
      </div>
    );
  }